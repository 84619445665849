<div class="page-container">
    <div class="search-results" *ngIf="!httpRunning && searchResults">
        <div class="search-results__container" *ngIf="searchResults.length > 0; else noResults">
            <h1 class="search-results__title">{{searchResults.length}} resultados para <strong>{{searchText}}</strong></h1>
            <div class="search-results__list">
                <app-product *ngFor="let product of searchResults" [data]="product"></app-product>
            </div>
        </div>

        <ng-template #noResults>
            <div class="search-results__empty">
                <p class="search-results__empty-message">
                    No se encontraron resultados que coincidan con tu búsqueda
                </p>
                <a href="/" class="button">Regresar al home</a>
            </div>
        </ng-template>
    </div>

</div>

<!-- FOOTER SECTION -->
<app-footer></app-footer>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/Services/Products/products.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  searchResults: any[] = [];
  searchText = '';
  httpRunning: boolean;

  constructor(
    private activeRoute: ActivatedRoute,
    private productService: ProductsService
  ) { }

  ngOnInit(): void {
    this.httpRunning = true;
    this.activeRoute.params.subscribe(params => {
      console.log(params);
      this.searchText = params.text;
      if (params.text && params.text != '') {
        this.productService.searchByText(params.text).then((response) => {
          console.log("SEARCH RESPONSE", response);
          if (response['status'] == 200 && response['data']) {
            this.searchResults = response['data'];
            this.httpRunning = false;
          }
          else {
            this.searchResults = [];
            this.httpRunning = false;
          }
        })
          .catch(err => {
            console.error(err);
            this.searchResults = [];
            this.httpRunning = false;
          });
      }
    })
  }

}

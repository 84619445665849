<footer class="main-footer">
    <div class="container">
        <div class="main-footer__top row">
            <div class="main-footer__brand col col__4 col__m3">
                <a href="/" routerLink="" routerLinkActive="active">
                    <img src="./assets/imgs/taptap_logo_complete.svg">
                </a>
            </div>
            <div class="main-footer__links col col__4 col__m4">
                <nav>
                    <h4 class="main-footer__links-title">TapTap</h4>
                    <ul class="main-footer__links-list">
                        <li><a [routerLink]="'/help/'">Ayuda</a></li>
                        <li><a [routerLink]="'/politics_privacy/'">Políticas de Privacidad</a></li>
                    </ul>
                </nav>
            </div>
            <div class="col col__4 col__m5">
                <div class="main-footer__download">
                    <h3 class="main-footer__download-title">Descárgala ¡HOY MISMO!</h3>
                    <div class="main-footer__download-links">
                        <a href="https://apps.apple.com/gt/app/tap-tap-app/id1473305356?l=en" target="_blank">
                            <img src="https://prototype-taptapapp.s3.amazonaws.com/assets/web/imgs/download-app-store.png"
                                alt="App Store">
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.taptapapp.storemvp&hl=es-419&ah=5iaZ04JINqV8lU42Xb42VQQ_mT4"
                            target="_blank">
                            <img src="https://prototype-taptapapp.s3.amazonaws.com/assets/web/imgs/download-play-store.png"
                                alt="Google Play">
                        </a>
                    </div>
                </div>
                <div class="main-footer__security">
                    <img src="./assets/imgs/envio_seguridad.png" alt="Envío gratis">
                </div>
            </div>
        </div>
        <div class="main-footer__bottom row">
            <div class="col col__12">
                <p>® 2020 TAPTAP. Todos los derechos reservados</p>
                <ul class="main-footer__social">
                    <li>
                        <a href="https://www.facebook.com/taptapappgt" target="blank">
                            <i class='tapicon-facebook'></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/taptapappgt" target="blank">
                            <i class='tapicon-instagram'></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://api.whatsapp.com/send?phone=50254932965" target="blank">
                            <i class='tapicon-whatsapp'></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
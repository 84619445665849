import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/Services/Products/products.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss']
})
export class SubcategoryComponent implements OnInit {

  subCategoryData: any;
  result: any = { status: null, message: null };
  categoryId;
  home: string;
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": true,
    "infinite": false,
    // "prevArrow": '.slider__button-prev',
    // "nextArrow": '.slider__button-next',
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          arrows: false,
        }
      }
    ]
  };

  constructor(
    private productsService: ProductsService,
    private actRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.actRoute.params.subscribe(params => {
      if (params && params.id) {
        this.categoryId = params.id;
        this.home = params.home || '1';
        this.loadData(params.id);
      }
      else {
        this.subCategoryData = null;
        this.result.status = 400;
        this.result.message = 'Aparentemente tienes un enlace con error...';
      }
    })
  }

  loadData(id) {
    this.productsService.getSubCategoriesAndProducts(id, '', this.home).then(response => {
      console.log("CATEGORY RESPONSE", response);
      if (response['status'] == 200 && response['data'] && response['data'].subcategories) {
        this.subCategoryData = response['data'];
        this.result.status = 200;
        this.result.message = null;
      }
      else if (response['status'] == 400 && response['message']) {
        this.subCategoryData = null;
        this.result.status = 400;
        this.result.message = 'Aparentemente tienes un enlace con error...';
      }
      else {
        this.subCategoryData = null;
        this.result.status = response['status'];
        this.result.message = 'Encontramos un error al cargar los elementos de esta categoría.';
      }
    })
      .catch(err => {
        console.error(err);
        this.subCategoryData = null;
        this.result.status = err['status'];
        this.result.message = 'Encontramos un error al cargar los elementos de esta categoría.';
      });
  }

  reload() {
    if (this.result.status == 400) {
      //this.navCtrl.setRoot('DashboardPage');
    }
    else {
      this.loadData(this.categoryId);
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/Services/Products/products.service';
import { environment } from '../../../environments/environment';
import { FirebaseService } from 'src/app/Services/Firebase/firebase.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  slug: any;
  product: any;
  alternativeProducts: any[] = [];
  imageIndex: number = 0;
  imageUrl: string;
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": true,
    "infinite": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        }
      }
    ]
  };
  faTimes = faTimes;

  constructor(
    private actRoute: ActivatedRoute,
    private productsService: ProductsService,
    public firebaseService: FirebaseService
  ) { }

  ngOnInit(): void {
    this.imageUrl = environment.imageUrl;

    this.actRoute.params.subscribe(params => {
      this.slug = this.actRoute.snapshot.params.id;
      this.alternativeProducts = [];
      if (this.slug) {
        this.productsService.getProductDetailBySlug(this.slug).then(response => {
          console.log("PRODUCT DETAIL " + this.slug + ":", response);

          if (response['success'] && response['status'] == 200 && response['data'] && response['data'].length > 0) {
            this.product = response['data'][0];
            console.log(this.product);

            this.productsService.getFamiliarProducts(this.product.id).then(familyResponse => {
              console.log("FAMILIAR PRODUCTS", familyResponse);
              if (familyResponse['success'] && familyResponse['status'] == 200 && familyResponse['data'] && familyResponse['data'].length > 0) {
                this.alternativeProducts = this.alternativeProducts.concat(familyResponse['data']);
              }
            })
              .catch(err => {
                console.error(err);
              });

            this.productsService.getAlternativeProducts(this.product.id).then(alterResponse => {
              console.log("ALTERNATIVE PRODUCTS", alterResponse);
              if (alterResponse['success'] && alterResponse['status'] == 200 && alterResponse['data'] && alterResponse['data'].length > 0) {
                this.alternativeProducts = this.alternativeProducts.concat(alterResponse['data']);
              }
            })
              .catch(err => {
                console.error(err);
              });
          }
        })
          .catch(err => {
            console.error(err);

          });
      }
    });
  }

  seeProductDetail(id, from, categories) {
    this.firebaseService.viewedProductDetail(id, from, categories);
  }

}

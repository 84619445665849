<header class="main-header">
  <div class="main-header__container container">
    <div class="main-header__menu">
      <button class="menu__button button button--clear button--icon-only navbar-toggler" type="button"
        (click)="toggleNavbar()" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="tapicon-menu"></i>
      </button>
      <a class="main-header__brand" routerLink="" routerLinkActive="active">
        <img src="./assets/imgs/taptap_logo_full.svg" class="hide-on__tablet">
        <img src="./assets/imgs/taptap_logo_mobile.svg" class="hide-on__laptop-up">
      </a>
      <!-- <nav class="menu__box collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }">
        <a class="menu__link" routerLink="/login" routerLinkActive="active">Login</a>
        <a class="menu__link" routerLink="/register" routerLinkActive="active">Register</a>
      </nav> -->
    </div>
    <div class="main-header__searchbar">
      <form class="searchbar" novalidate (ngSubmit)="searchResults()" [formGroup]="searchForm">
        <label for="search_product" class="searchbar__icon">
          <i class="tapicon-search"></i>
        </label>
        <input type="text" id="search_product" placeholder="¿Qué buscas?" class="searchbar__input"
          formControlName="text">
      </form>
    </div>
    <!-- <div class="main-header__orders hide-on__laptop-up">
      <ul class="navbar">
        <li class="navbar__item navbar__item--opaque">
          <a class="navbar__link">
            <i class="tapicon-orders"></i>
          </a>
        </li>
      </ul>
    </div> -->
    <nav class="main-header__nav">
      <ul class="navbar">
        <li class="navbar__item">
          <a href="https://api.whatsapp.com/send?phone=50254932965" target="blank" class="navbar__link">
            <i class="tapicon-whatsapp"></i>
            <span class="navbar__link-text">Ayuda</span>
          </a>
        </li>
        <!-- <li class="navbar__item">
          <a href="" class="navbar__link">
            <i class="tapicon-list"></i>
            <span class="navbar__link-text">Listas</span>
          </a>
        </li>
        <li class="navbar__item">
          <a href="" class="navbar__link">
            <i class="tapicon-reminders"></i>
            <span class="navbar__link-text">Recordatorios</span>
          </a>
        </li>
        <li class="navbar__item navbar__item--separator">
          <a href="" class="navbar__link">
            <i class="tapicon-wallet"></i>
            <span class="navbar__link-text">Billetera</span>
          </a>
        </li>
        <li class="navbar__item navbar__item--opaque hide-on__tablet">
          <a href="" class="navbar__link">
            <i class="tapicon-orders"></i>
          </a>
        </li>
        <li class="navbar__cart navbar__item navbar__item--opaque">
          <a href="" class="navbar__cart navbar__link">
            <i class="tapicon-cart"></i>
            <span class="navbar__cart-count">0</span>
          </a>
        </li> -->
      </ul>
    </nav>
  </div>
</header>

<div class="main-content">
  <router-outlet></router-outlet>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProductsService } from 'src/app/Services/Products/products.service';
import { forkJoin } from 'rxjs';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  env = environment;
  normalCategories: any[] = [];
  wizardCategories: any[] = [];
  promoCategories: any[] = [];
  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": false,
    "infinite": true
  };
  imageUrl: string;

  constructor(
    private productService: ProductsService
  ) {
    console.log("ambiente: " + this.env.environment_name);

  }

  ngOnInit(): void {
    this.imageUrl = environment.imageUrl;
    let obs1 = this.productService.getNormalCategories().catch(err => {
      console.error(err);
      this.normalCategories = [];
    });

    let obs2 = this.productService.getPromoCategories().catch(err => {
      console.error(err);
      this.promoCategories = [];
    });

    let obs3 = this.productService.getWizardCategories().catch(err => {
      console.error(err);
      this.wizardCategories = [];
    });

    forkJoin([obs1, obs2, obs3]).subscribe(response => {
      console.log("RESPUESTAS DEL HOME", response);

      if (response[0] && response[0]['status'] == 200 && response[0]['data'] && response[0]['data'].length > 0) {
        this.normalCategories = response[0]['data'];
      }

      if (response[1] && response[1]['status'] == 200 && response[1]['data'] && response[1]['data'].length > 0) {
        let temp = response[1]['data'];
        if (temp.length > 0) {
          this.promoCategories = new Array();
          temp.forEach(element => {
            this.productService.getSubCategoriesAndProducts(element.id, '', '', 'sidebar').then(resp => {
              console.log("PROMO PRODUCTS", resp);
              if (resp['status'] == 200 && resp['data'] && resp['data'].subcategories && resp['data'].subcategories.length > 0 && resp['data'].subcategories[0].products && resp['data'].subcategories[0].products.length > 0) {
                element['products'] = resp['data'].subcategories[0].products;                
                if (this.promoCategories.findIndex(a => a.id == element.id) == -1 && element.products)
                  this.promoCategories.push(element);
              }
            })
              .catch(err => {
                console.error(err);
              });
          });
        }
      }

      if (response[2] && response[2]['status'] == 200 && response[2]['data'] && response[2]['data'].length > 0) {
        this.wizardCategories = response[2]['data'];
      }
    }, err => {
      console.error(err);
    }, () => {

    });

  }

  slideNext(){
    this.slickModal.slickNext();
  }

  slideBack(){
    this.slickModal.slickPrev();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SharedService } from '../Shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private httpClient: HttpClient,
    private sharedService: SharedService
  ) { }

  //Section for categories
  getNormalCategories() {
    return this.httpClient.get(environment.productsUrl + 'category/main').toPromise();
  }

  getPromoCategories() {
    return this.httpClient.get(environment.productsUrl + 'category/impulsar').toPromise();
  }

  getWizardCategories() {
    return this.httpClient.get(environment.productsUrl + 'category/checklist').toPromise();
  }

  //Section for products
  getProductDetail(productId: any) {
    /* let params = new HttpParams();
    params = params.append('household_id', household); */
    return this.httpClient.get(environment.productsUrl + `products/${productId}`).toPromise();
  }

  getAlternativeProducts(productId) {
    return this.httpClient.get(environment.productsUrl + `products/${productId}/alter`).toPromise();
  }

  getFamiliarProducts(productId) {
    return this.httpClient.get(environment.productsUrl + `products/${productId}/family`).toPromise();
  }

  getSubCategoriesAndProducts(category, brands, home, fieldset = 'default') {
    let prodForm = new FormData();
    prodForm.append('category_id', category);
    prodForm.append('home', home);
    prodForm.append('brands', brands);
    prodForm.append('fieldset', fieldset);
    return this.httpClient.post(environment.productsUrl + 'products/category', prodForm).toPromise();
  }

  getProductDetailBySlug(slug: string) {
    /* let params = new HttpParams();
    params = params.append('household_id', household); */
    return this.httpClient.get(environment.productsUrl + `products/slug/${slug}`).toPromise();
  }

  searchByText(text) {
    let searchData = new FormData();
    searchData.append('text', text);

    return this.httpClient.post(environment.productsUrl + "products/search", searchData).toPromise();
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryString'
})
export class CategoryStringPipe implements PipeTransform {

  transform(categories: any[]): string {
    if (categories && categories.length > 0) {
      return categories.filter(a=>a.parent_id && a.parent_id > 0).map(a => a.name).join(", ");
    }
    return null;
  }

}

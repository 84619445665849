import { Component, OnInit, Input } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { FirebaseService } from "../../../Services/Firebase/firebase.service";

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input() data;
  imageUrl;

  constructor(
    private firebaseService: FirebaseService
  ) { }

  ngOnInit(): void {    
    this.imageUrl = environment.imageUrl;
  }

  seeProductDetail() {
    this.firebaseService.viewedProductDetail(this.data.id, this.firebaseService.fromValues.prodPage, this.data.categories);
  }

}

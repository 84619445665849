import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './Components/main/main.component';
import { SignInComponent } from './Components/sign-in/sign-in.component';
import { SignUpComponent } from './Components/sign-up/sign-up.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { HomeComponent } from './Components/home/home.component';
import { SubcategoryComponent } from './Components/subcategory/subcategory.component';
import { ProductDetailComponent } from './Components/product-detail/product-detail.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { PoliticsComponent } from './components/politics/politics.component';
import { HelpComponent } from './components/help/help.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '', component: MainComponent, children: [
      { path: 'home', component: HomeComponent },
      { path: 'category/:id/:home', component: SubcategoryComponent },
      { path: 'category/:id', component: SubcategoryComponent },
      { path: 'product/:id', component: ProductDetailComponent },
      { path: 'search-results/:text', component: SearchResultsComponent },
      { path: 'politics_privacy', component: PoliticsComponent },
      { path: 'help', component: HelpComponent }
    ]
  },
  { path: 'login', component: SignInComponent },
  { path: 'register', component: SignUpComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

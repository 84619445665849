<div class="page-container">
    <!-- hero banner -->
    <section class="hero-banner">
        <!--  <div class="hero-banner__slider swiper-container">
            <div class="hero-banner__slider-wrapper swiper-wrapper">
                 <div class="hero-banner__slider-slide swiper-slide">
                    <img src="/assets/sample_images/hero-banner_1.jpg" alt="">
                </div>
                <div class="hero-banner__slider-slide swiper-slide">Slide 2</div>
                
            </div>
        </div> -->
        <div class="hero-banner__slider slider">
            <ngx-slick-carousel class="hero-banner__slider-wrapper slider__container" #slickModal="slick-carousel"
                [config]="slideConfig">
                <div ngxSlickItem class="hero-banner__item slider__item">
                    <img src="/assets/sample_images/hero-banner_1.jpg" alt="">
                </div>
                <div ngxSlickItem class="hero-banner__item slider__item">
                    <img src="/assets/sample_images/hero-banner_1.jpg" alt="">
                </div>
            </ngx-slick-carousel>
        </div>

        <button class="hero-banner__nav-next slider__button-next" (click)="slideNext()">
            <i class='tapicon-dir-right'></i>
        </button>
        <button class="hero-banner__nav-prev slider__button-prev" (click)="slideBack()">
            <i class='tapicon-dir-left'></i>
        </button>
    </section>

    <div *ngIf="promoCategories && promoCategories.length > 0">
        <section class="featured featured--blue" *ngFor="let category of promoCategories"
            [style.background-color]="category.color ? category.color : '#6C9C9C'">
            <div class="featured__meta">
                <h2 class="featured__title">
                    <span class="screen-reader-text">{{category?.name}}</span>
                    <img [src]="category?.image">
                </h2>
                <a class="featured__button button button--icon-end" [routerLink]="'/category/' + category.id + '/0'">
                    Ver todos
                    <i class='tapicon-dir-right'></i>
                </a>
            </div>
            <div class="featured__list" *ngIf="category.products && category.products.length > 0">
                <article class="product box" *ngFor="let product of category.products | slice:0:6;"
                    [routerLink]="'/product/' + product.slug">
                    <div class="product__image">
                        <a class="product__image-link">
                            <img [src]="product.image ? imageUrl + 'thumb/' + product.image : 'assets/imgs/no-photo.png'"
                                [alt]="product?.name">
                        </a>
                    </div>
                    <a class="product__data">
                        <h3 class="product__name">
                            {{product?.marca}} {{product?.name}}
                        </h3>
                    </a>
                </article>
            </div>
        </section>
         
    </div>

    <!-- cateories list -->
    <section class="categories-list" *ngIf="normalCategories && normalCategories.length > 0">
        <h2 class="title">Categorías</h2>
        <div class="categories-list__container">
            <article class="category-tile title-rt img-b" [routerLink]="'/category/' + '11000'">
                <a class="category-tile__link">
                    <h3 class="category-tile__title"><span>Cuidado</span> personal</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/cuidado-personal_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/cuidado-personal_img.png">
                </a>
            </article>
            <article class="category-tile title-ct img-b" [routerLink]="'/category/' + '11000'">
                <a class="category-tile__link">
                    <h3 class="category-tile__title"><span>Productos</span> de papel</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/productos-papel_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/productos-papel_img.png">
                </a>
            </article>
            <article class="category-tile title-lt img-b" [routerLink]="'/category/' + '11000'">
                <a class="category-tile__link">
                    <h3 class="category-tile__title"><span>Limpieza</span> de ropa</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/limpieza-ropa_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/limpieza-ropa_img.png">
                </a>
            </article>
            <article class="category-tile title-ct img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title">Salsas</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/salsas_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/salsas_img.png">
                </a>
            </article>
            <article class="category-tile title-rt img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Cuidado</span> personal</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/cuidado-personal_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/cuidado-personal_img.png">
                </a>
            </article>
            <article class="category-tile title-ct img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Productos</span> de papel</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/productos-papel_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/productos-papel_img.png">
                </a>
            </article>
            <article class="category-tile title-lt img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Limpieza</span> de ropa</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/limpieza-ropa_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/limpieza-ropa_img.png">
                </a>
            </article>
            <article class="category-tile title-ct img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title">Salsas</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/salsas_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/salsas_img.png">
                </a>
            </article>
            <article class="category-tile title-rt img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Cuidado</span> personal</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/cuidado-personal_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/cuidado-personal_img.png">
                </a>
            </article>
            <article class="category-tile title-ct img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Productos</span> de papel</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/productos-papel_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/productos-papel_img.png">
                </a>
            </article>
            <article class="category-tile title-lt img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Limpieza</span> de ropa</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/limpieza-ropa_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/limpieza-ropa_img.png">
                </a>
            </article>
            <article class="category-tile title-ct img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title">Salsas</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/salsas_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/salsas_img.png">
                </a>
            </article>
            <article class="category-tile title-rt img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Cuidado</span> personal</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/cuidado-personal_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/cuidado-personal_img.png">
                </a>
            </article>
            <article class="category-tile title-ct img-b">
                <a href="#" class="category-tile__link">
                    <h3 class="category-tile__title"><span>Productos</span> de papel</h3>
                    <img class="category-tile__vector" src="/assets/imgs/categories/productos-papel_vector.png">
                    <img class="category-tile__img" src="/assets/imgs/categories/productos-papel_img.png">
                </a>
            </article>
        </div>
    </section>

</div>

<!-- FOOTER SECTION -->
<app-footer></app-footer>
<article class="product box" [routerLink]="'/product/' + data.slug" (click)="seeProductDetail()">
    <div class="product__image">
        <a class="product__image-link">
            <img [src]="imageUrl + 'small/' + data.image">
        </a>
        <!-- <button class="product__add">
            <i class="tapicon-cart-add"></i>
        </button> -->
    </div>
    <a class="product__data">
        <h3 class="product__name">
            {{data?.marca}} {{data?.name}}
        </h3>
        <ul class="product__table" *ngIf="data.units && data.metric">
            <li class="product__table-item">
                <p class="product__metric">{{data.units}} {{data.metric}}</p>
                <p class="product__price">{{data?.price | localCurrency}}</p>
            </li>
        </ul>
    </a>
</article>
<div class="page-container">
    <div class="subcategory" *ngIf="result && result.status">
        <div class="subcategory__container"
            *ngIf="subCategoryData && subCategoryData.subcategories && subCategoryData.subcategories.length; else noData">
            <h1 class="subcategory__title">Perros</h1>

            <div *ngIf="subCategoryData.subcategories.length > 0; else noProducts">

                <!-- SECCION PARA UNA CATEGORIA QUE TIENE VARIAS SUBCATEGORIAS -->
                <div *ngIf="subCategoryData.is_sub_category_available">
                    <div class="subcategory_products product-list"
                        *ngFor="let category of subCategoryData.subcategories; let i = index;">
                        <h2 class="product-list__title title">
                            {{category?.sub_cat_name}}
                            <a href="" class="product-list__title-link" [routerLink]="'/category/' + category.sub_cat_id + '/0'">
                                Ver todos ({{category?.todos}})
                                <i class='tapicon-dir-right'></i>
                            </a>
                        </h2>
                        <div class="product-list__slider slider">
                            <ngx-slick-carousel #slickModal="slick-carousel" class="product-list__slider-container slider__container" [config]="slideConfig">
                                <app-product [data]="product" ngxSlickItem class="product-list__item slider__item" *ngFor="let product of category?.products;"></app-product>
                                <div class="product-list__item slider__item">
                                    <button class="product-list__more" *ngIf="category.todos && category.todos > 4" [routerLink]="'/category/' + category.sub_cat_id + '/0'">
                                        Ver todos ({{category?.todos || 0}})
                                    </button>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div>
                </div>

                <!-- SECCION PARA UNA CATEGORIA QUE NO TIENE SUBCATEGORIAS -->
                <div *ngIf="!subCategoryData.is_sub_category_available">
                    <div class="subcategory__list"
                        *ngIf="subCategoryData.subcategories[0] && subCategoryData.subcategories[0].products && subCategoryData.subcategories[0].products.length; else noProducts">
                        <app-product *ngFor="let product of subCategoryData.subcategories[0].products" [data]="product">
                        </app-product>
                    </div>
                </div>

            </div>

            <ng-template #noProducts>
                <div>
                    <img src="assets/imgs/no-products.png" style="mix-blend-mode: darken;">
                    <h6>
                        Ya casi estamos listos para ofrecerte artículos de esta categoria.
                        Te enviaremos una notificación cuando estén disponible.
                    </h6>
                </div>
            </ng-template>

        </div>

        <ng-template #noData>

            <div>
                <img src="assets/imgs/no-products.png" style="mix-blend-mode: darken;">
                <h6>{{result.message}}</h6>
                <button (click)="reload()">{{result.status == 400 ? 'Regresar': 'Recargar'}}</button>
            </div>

        </ng-template>
    </div>

</div>

<!-- FOOTER SECTION -->
<app-footer></app-footer>
<div class="page-container" *ngIf="slug && product">
    <div class="product-detail box box--large">
        <div class="product-detail__container">
            <!-- images section -->
            <div class="product-detail__images">
                <img class="product-detail__images-img"
                    [src]="product.image ? imageUrl + 'full/' + product.image : 'assets/imgs/no-photo.png'">
                <!-- <div class="product-detail__images-group" *ngIf="product?.image.length > 1">
                    <div class="product-detail__images-thumbnail" *ngFor="let image of product?.image; let i = index;" (click)="imageIndex = i;">
                        <img [src]="image?.thumb_image" >
                    </div>
                </div> -->
            </div>
            <div class="product-detail__data">
                <h2 class="product-detail__data-category">{{product?.categories | categoryString}}</h2>
                <h1 class="product-detail__data-name">{{product?.name}}</h1>
                <!-- presentations section -->
                <div class="presentations">
                    <div class="presentations__container">
                        <a class="presentations__item current">
                            <div class="presentations__item-product">
                                <img class="presentations__item-img"
                                    [src]="product.image ? imageUrl + 'thumb/' + product.image : 'assets/imgs/no-photo.png'">
                                <p class="presentations__item-name">{{product?.units}} {{product?.metric}}</p>
                            </div>
                            <p class="presentations__item-price">{{product?.price | localCurrency}}</p>
                        </a>
                        <a [routerLink]="'/product/' + unit.slug"
                            (click)="seeProductDetail(unit.id, firebaseService.fromValues.changePresentation, unit.categories)"
                            class="presentations__item" *ngFor="let unit of product?.presentations">
                            <div class="presentations__item-product">
                                <img class="presentations__item-img"
                                    [src]="unit.image ? imageUrl + 'thumb/' + unit.image : 'assets/imgs/no-photo.png'">
                                <p class="presentations__item-name">{{unit?.units}} {{unit?.metric}}</p>
                            </div>
                            <p class="presentations__item-price">{{unit?.price | localCurrency}}</p>
                        </a>
                    </div>
                </div>

                <div class="product-detail__cart">
                    <!-- prices section -->
                    <h3 class="product-detail__cart-price">
                        {{product?.price | localCurrency}}

                    </h3>
                    <!-- cart button -->
                    <button class="product-detail__cart-button button button--icon-end">
                        Comprar en el App
                        <i class='tapicon-cart-add'></i>
                    </button>
                    <button shareButtonsPopup="true" [include]="['facebook','twitter', 'whatsapp', 'copy']" [closeIcon]="faTimes"
                        class="product-detail__cart-share hide-on__tablet-up button button--icon-only button--white">
                        <i class='tapicon-share'></i>
                    </button>
                    <p class="product-detail__cart-date">
                        Fecha de entrega:
                        <strong>{{product?.delivery_date | dateFormat}}</strong>
                    </p>
                </div>
                <!-- share section -->
                <div class="product-detail__share hide-on__phone">
                    <div class="product-detail__share-label">Compartir</div>
                    <div class="product-detail__share-links">
                        <a shareButton="facebook">
                            <i class="fa fa-facebook-f"></i>
                        </a>
                        <a shareButton="twitter">
                            <i class="fa fa-twitter"></i>
                        </a>
                        <a shareButton="whatsapp">
                            <i class="fa fa-whatsapp"></i>
                        </a>
                    </div>
                </div>
                <!-- description section -->
                <div class="product-detail__description" *ngIf="product?.description">
                    <h3 class="product-detail__description-title">Descripción</h3>
                    <p>{{product?.description}}</p>
                </div>
            </div>
        </div>
    </div>


    <!-- ALTER PRODUCTS SECTION -->
    <div class="product-list" *ngIf="alternativeProducts && alternativeProducts.length > 0">
        <h2 class="product-list__title title">Alternativas</h2>
        <!-- <div class="product-list__scroll">
            <div class="product-list__scroll-container">
                <app-product *ngFor="let product of alternativeProducts" [data]="product"></app-product>
            </div>
        </div> -->
        <div class="product-list__slider slider">
            <ngx-slick-carousel #slickModal="slick-carousel" class="product-list__slider-container slider__container" [config]="slideConfig">
                <app-product [data]="product" ngxSlickItem class="product-list__item slider__item" *ngFor="let product of alternativeProducts"></app-product>
            </ngx-slick-carousel>
        </div>
    </div>



</div>

<!-- FOOTER SECTION -->
<app-footer></app-footer>
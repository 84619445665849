import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: any): any {
    moment.locale('es');
    return moment(date, "YYYY-MM-DD").format('dddd DD [de] MMMM [del] YY');
  }

}

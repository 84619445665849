import { Component } from '@angular/core';
import { SharedService } from './Services/Shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private sharedService: SharedService
  ) {
    this.getIp();
  }

  async getIp() {
    let data = await this.sharedService.getIPAddress();
    if (data && data['ip'])
      this.sharedService.setLocalIp(data['ip']);
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Librerias
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, ScreenTrackingService, APP_NAME, APP_VERSION } from '@angular/fire/analytics';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { OverlayModule } from '@angular/cdk/overlay';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//Componentes
import { MainComponent } from './Components/main/main.component';
import { SignUpComponent } from './Components/sign-up/sign-up.component';
import { SignInComponent } from './Components/sign-in/sign-in.component';
import { PageNotFoundComponent } from './Components/page-not-found/page-not-found.component';
import { HomeComponent } from './Components/home/home.component';
import { SubcategoryComponent } from './Components/subcategory/subcategory.component';
import { ProductDetailComponent } from './Components/product-detail/product-detail.component';
import { ProductComponent } from './Components/Generics/product/product.component';
import { FooterComponent } from './Components/Generics/footer/footer.component';
import { LocalCurrencyPipe } from './pipes/LocalCurrency/local-currency.pipe';
import { DateFormatPipe } from './pipes/DateFormat/date-format.pipe';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CategoryStringPipe } from './pipes/CategoryString/category-string.pipe';
import { PoliticsComponent } from './components/politics/politics.component';
import { HelpComponent } from './components/help/help.component';
import { InterceptorService } from './Services/Interceptor/interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    SignUpComponent,
    SignInComponent,
    PageNotFoundComponent,
    HomeComponent,
    SubcategoryComponent,
    ProductDetailComponent,
    ProductComponent,
    FooterComponent,
    LocalCurrencyPipe,
    DateFormatPipe,
    SearchResultsComponent,
    CategoryStringPipe,
    PoliticsComponent,
    HelpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    HttpClientModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    ShareButtonsModule.withConfig({ debug: true }),
    ShareModule.withConfig({ debug: true }),
    ShareButtonsPopupModule,
    ShareIconsModule,
    OverlayModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: APP_NAME, useValue: environment.appName
    },
    {
      provide: APP_VERSION, useValue: environment.appVersion
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

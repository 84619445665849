import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getUserId() {
    return localStorage.getItem("userId");
  }

  //Get and set ip
  getLocalIp() {
    return localStorage.getItem('localIp');
  }

  setLocalIp(ip) {
    localStorage.setItem("localIp", ip);
  }

  getIPAddress() {
    return this.httpClient.get("https://api.ipify.org/?format=json").toPromise();
  }
}

import { environment as defaultEnvironment } from './environment.default';

export const environment = {
    appName: defaultEnvironment.appName,
    appVersion: defaultEnvironment.appVersion,
    imageUrl: defaultEnvironment.imageUrl,
    environment_name: 'dev',
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyDSbUNlC5cUlCLOH3_Rw1_IuTQ8JoKBosU",
        authDomain: "dev-taptapapp.firebaseapp.com",
        databaseURL: "https://dev-taptapapp.firebaseio.com",
        projectId: "dev-taptapapp",
        storageBucket: "dev-taptapapp.appspot.com",
        messagingSenderId: "673013906965",
        appId: "1:673013906965:web:b256a8bb2787b42090981c",
        measurementId: "G-0SHC7MD2XQ"
    },
    apiUrl: 'https://api-ci.dev.taptapapp.com/api/mobile/',
    productsUrl: 'https://product.dev.taptapapp.com/api/v1/'
};
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public events = {
    topMenu: "TopMenu",
    invitedFriend: "InvitedFriend",
    registeredFamilyMember: "RegisteredFamilyMember",
    addedProdToCart: "AddedProdToCart",
    searchedText: "SearchedText",
    enteredScanBarCode: "EnteredScanBarCode",
    scannedBarCode: "ScannedBarCode",
    removedProdFromCart: "RemovedProdFromCart",
    addedProdCartQuantity: "AddedProdCartQuantity",
    removedProdCartQuantity: "RemovedProdCartQuantity",
    viewedProd: "ViewedProd",
    addedProdToList: "AddedProdToList",
    removedProdFromlist: "RemovedProdFromList",
    placedOrder: "PlacedOrder",
    didNotFindGeographicArea: "DidNotFindGeographicArea",
    didNotFindZone: "DidNotFindZone",
    enteredEmptyCategory: "EnteredEmptyCategory",
    enteredCategory: "EnteredCategory",
    enteredSubcategory: "EnteredSubcategory",
    deleteRecurrenceSettings: "DeleteRecurrenceSettings",
    adjustedRecurrenceSettings: "AdjustedRecurrenceSettings",
    createdRecurrenceSettings: "CreatedRecurrenceSettings",
    openedCreditCardRegister: "OpenedCreditCardRegister",
    openedAddressRegister: "OpenedAddressRegister",
    enteredCheckout: "EnteredCheckout",
    clickMainMenu: "ClickMainMenu",
    login: "login",
    sign_up: "sign_up",
    outOfStock: "OutOfStock",
    purchased_product: "purchasedProduct",
    checklistCategory: "ChecklistCategory",
    checklistSubcategory: "ChecklistSubcategory",
    enteredCart: "EnteredCart",
    displayedHomeSection: 'DisplayedHomeSection',
    savedAddress: "SavedAddress",
    savedCreditCard: "SavedCreditCard",
    pageEntered: "PageEntered",
    viewedAlternatives: "ViewedAlternatives",
    displayedProductSection: "DisplayedProductSection",
  }

  public properties = {
    screen: "screen",
    via: "via",
    prodId: "ProdId",
    prodName: "ProdName",
    from: "From",
    criteria: "criteria",
    results: "results",
    success: "success",
    barCode: "barCode",
    isPersonal: "isPersonal",
    listId: "ListId",
    listName: "ListName",
    geographicAreaId: "GeographicAreaId",
    categoryId: "CategoryId",
    categoryName: "CategoryName",
    subcategoryId: "SubcategoryId",
    subcategoryName: "SubCategoryName",
    parentCategoryId: "ParentCategoryId",
    parentCategoryName: "ParentCategoryName",
    quantity: "Quantity",
    method: "method",
    category: "category",
    value: "value",
    products: "products",
    num_items: "num_items",
    currency: "currency",
    total: "total",
    product: "product",
    transaction_order: "transaction_order",
    section: 'section',
    pageName: "pageName",
    pageId: "pageId",
    routeCounter: "routeCounter",
    route: "route",
    complex: "complex",
    zone: "zone",
    action: "action"
  }

  public fromValues = {
    prodPage: "ProdPage",
    cart: "Cart",
    checkoutInfo: "CheckoutInfo",
    afterOrder: "AfterOrder",
    scannerCode: "ScannerCode",
    personalList: "PersonalList",
    familyList: "FamilyList",
    orderList: "OrderList",
    changePresentation: "ChangePresentation",
    reminderList: "ReminderList",
    searchList: "SearchList",
    recentList: "RecentList",
    subCategories: "SubCategories",
    wizardCategory: "WizardCategory",
    categoryList: "CategoryList",
    subcategoryList: "SubcategoryList",
    familyProducts: "FamilyProducts",
    myAccount: "MyAccount",
    complementaryProducts: "ComplementaryProducts",
    productDescription: "ProductDescription",
    listComponent: "ListComponent",
    featuredProducts: "FeaturedProducts",
    alterProducts: "AlterProducts",
    addOnProducts: "AddOnProducts"
  }

  public actions = {
    create: "create",
    edit: "edit",
    delete: "delete"
  }

  constructor(
    private analytics: AngularFireAnalytics
  ) { }

  logEvent(name: string, params: any){
    this.analytics.logEvent(name, params);
  }

  setUserId(id){
    this.analytics.setUserId(id);
  }

  setUserProperty(property, data){
    this.analytics.setUserProperties(property, data);
  }

  productEventParams(id, from, categories) {
    let eventParams = {};
    eventParams[this.properties.prodId] = id;
    eventParams[this.properties.from] = from;
    if (categories) {
      eventParams[this.properties.parentCategoryId] = JSON.stringify(categories.map(c => c.parent_id));
      eventParams[this.properties.subcategoryId] = JSON.stringify(categories.map(c => c.id));
    }
    return eventParams;
  }

  viewedProductDetail(id, from, categories) {
    let eventParams = this.productEventParams(id, from, categories);
    this.logEvent(this.events.viewedProd, eventParams);
  }

  addedProdToCart(id, from, categories) {
    let eventParams = this.productEventParams(id, from, categories);
    this.logEvent(this.events.addedProdToCart, eventParams);
  }

  addedProdToCartQuantity(id, from, categories) {
    let eventParams = this.productEventParams(id, from, categories);
    this.logEvent(this.events.addedProdCartQuantity, eventParams);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localCurrency'
})
export class LocalCurrencyPipe implements PipeTransform {

  transform(value: any): any {
    var v = parseFloat(value).toFixed(2);
    if (v.toString() == 'NaN') {
      return "Q" + value;
    }
    else {
      return "Q" + v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

}

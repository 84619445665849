import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  navbarOpen = false;
  searchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      text: new FormControl('', [Validators.required])
    });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  searchResults() {
    if (this.searchForm.valid) {
      this.router.navigate(['/search-results/' + this.searchForm.get('text').value]);
      this.searchForm.get('text').setValue('');
    }
  }

}
